program Menu2;

{$mode objfpc}
{$modeswitch externalclass}
uses
  browserapp, JS, Classes, SysUtils, Web;

const
//  menuData='{"message":"Proszę się zalogować", "menudata":[{"id":"menuLogin","name":"Zaloguj","type":"link","data":[]}]}';
  menuData='[{"id":"menuLogin","name":"Zaloguj","type":"link","data":[]}]';

type

  { TBootstrap }

  TBootstrapModalOptions = Class external name 'Object' (TJSObject)
    show : boolean;
    focus : boolean;
    keyboard : boolean;
    backdrop : boolean;
  end;

  TSBootstrapModal = class external name 'bootstrap.Modal' (TJSObject)
  public
    constructor New;
    constructor New(const AObject: TJSObject);
    constructor New(const AObject: TJSObject; AOptions: TBootstrapModalOptions);
    procedure show;
    procedure hide;
    procedure handleUpdate;
  end;

type

  { TMenu2Application }

  TMenu2Application = class(TBrowserApplication)
    procedure BindElements;
    procedure ShowLoginForm(aCommand: string);
    procedure DeleteMenu(aParent: TJSHTMLElement);
    procedure CreateMenu(aParent: TJSHTMLElement; aJson: string;
      isSubnode: boolean=False);
    function CreateMenuItem(aParent: TJSHTMLElement; JO: TJSObject;
      isSubnode: boolean=False): TJSHTMLElement;
    procedure ClearShowAttribute(aParentNode: TJSHTMLElement;
      aNodeExclude: TJSHTMLElement=nil);
    procedure SetShowAttribute(aNode: TJSHTMLElement);
    procedure doRun; override;
    function onMenuClick(Event: TJSMouseEvent): boolean;
    function onLoadData(Event: TEventListenerEvent): boolean;
    procedure deleteOldCookies;
    published
      function pokaz(const tekst:string): boolean;
  end;

 var
  xhr:TJSXMLHttpRequest;
  navcontent : TJSHTMLElement;
  varcontent : TJSHTMLElement;
  logincontent  : TJSHTMLElement;
  divnewpwd, divtotp: TJSHTMLElement;

  bsmodal: TSBootstrapModal;


procedure TMenu2Application.BindElements;
var
  bsmodaloptions: TBootstrapModalOptions;
begin
  navcontent:= TJSHTMLElement(document.getelementByID('navcontent'));
  varcontent:= TJSHTMLElement(document.getelementByID('varcontent'));
  logincontent:= TJSHTMLElement(document.getelementByID('logincontent'));
  logincontent.onclick:= @onMenuClick;
  divnewpwd:= TJSHTMLElement(document.getelementByID('divnewpwd'));
  divtotp:= TJSHTMLElement(document.getelementByID('divtotp'));
  bsmodaloptions:= TBootstrapModalOptions.new;
  bsmodaloptions.backdrop:= False;
  bsmodal:= TSBootstrapModal.New(logincontent, bsmodaloptions);

end;

procedure TMenu2Application.ShowLoginForm(aCommand: string);
var
  aInputElementUname: TJSHTMLInputElement;
  aInputElementPwd1: TJSHTMLInputElement;
  aInputElementPwd2: TJSHTMLInputElement;
  aInputElementPwd3: TJSHTMLInputElement;
  aInputElementTotp: TJSHTMLInputElement;
begin
  aInputElementUname:= TJSHTMLInputElement(GetHTMLElement('uname')) ;
  aInputElementPwd1:= TJSHTMLInputElement(GetHTMLElement('pwd1')) ;
  aInputElementPwd2:= TJSHTMLInputElement(GetHTMLElement('pwd2')) ;
  aInputElementPwd3:= TJSHTMLInputElement(GetHTMLElement('pwd3')) ;
  aInputElementTotp:= TJSHTMLInputElement(GetHTMLElement('totp')) ;

  aInputElementPwd2.value:='';
  aInputElementPwd3.value:='';
  aInputElementTotp.value:='';

  case aCommand of
  'menuLogoff':
    begin
      aInputElementUname.value:='';
      aInputElementPwd1.value:='';
      DeleteMenu(navcontent);
      CreateMenu(navcontent, menuData);
      ShowLoginForm('menuLogin');
    end;
  'menuLogin':
    begin
      divnewpwd.classList.add('collapse');
      divtotp.classList.add('collapse');
      aInputElementUname.readOnly:= false;
      aInputElementPwd1.readOnly:= false;
      aInputElementPwd2.disabled:= true;
      aInputElementPwd3.disabled:= true;
      aInputElementTotp.disabled:= true;
      bsmodal.show;
      aInputElementUname.focus;

    end;
  'menuPwdChange':
    begin
      divnewpwd.classList.add('collapse');
      divnewpwd.classList.remove('collapse');
      aInputElementUname.readOnly:= true;
      aInputElementPwd1.readOnly:= true;
      aInputElementPwd2.disabled:= false;
      aInputElementPwd3.disabled:= false;
      aInputElementTotp.disabled:= true;
      bsmodal.show;
      aInputElementPwd2.focus;
    end;
  'menuTotp':
    begin
      divnewpwd.classList.add('collapse');
      divtotp.classList.remove('collapse');
      aInputElementUname.readOnly:= true;
      aInputElementPwd1.readOnly:= true;
      aInputElementPwd2.disabled:= true;
      aInputElementPwd3.disabled:= true;
      aInputElementTotp.disabled:= false;
      bsmodal.show;
      aInputElementTotp.focus;
    end;
  'btncancel':
    begin
      bsmodal.hide;
    end;
  'btnlogin':
    begin
      deleteOldCookies;
    end;
  end;
end;

procedure TMenu2Application.DeleteMenu(aParent: TJSHTMLElement);
begin
  while aParent.hasChildNodes and assigned(aParent.lastElementChild) do
    aParent.removeChild(aParent.lastElementChild);
end;

function TMenu2Application.CreateMenuItem(aParent: TJSHTMLElement; JO: TJSObject; isSubnode: boolean=False):TJSHTMLElement;
var
  aType, aName, aId: string;
  aData: TJSObject;
  menuitem, ul: TJSHTMLElement;
  a: TJSHTMLAnchorElement;
  input: TJSHTMLInputElement;
  select: TJSHTMLSelectElement;
  option: TJSHTMLOptionElement;
  optgroup: TJSHTMLOptGroupElement;
  dataKeys: array of String;
  dataKey: string;
begin
  aType:= string(JO.Properties['type']);
  aName:= string(JO.Properties['name']);
  aId:= string(JO.Properties['id']);

  if (aType <> 'option') and (aType <> 'optgroup') then
  begin
    menuitem:= TJSHTMLElement(Document.createElement('li'));
    aParent.appendChild(menuitem);
    result:= menuitem;
  end;

  case aType of
    'label':
      begin
        menuitem.id:= aId;
        menuitem.innerText:= aName;
      end;
    'link':
      begin
        aData:= TJSObject(JO.Properties['data']);
        a:= TJSHTMLAnchorElement(Document.createElement('a'));
        a['href']:='#';
        a.id:= aId;
        a.innerText:= aName;
        a.onclick:= @onMenuClick;
        dataKeys:= TJSObject.getOwnPropertyNames(aData);
        for dataKey in dataKeys do
          a.dataset.Properties[datakey]:= aData.Properties[dataKey];
        menuitem.appendChild(a);
        result:= a;
        if isSubnode then
          begin
            a.className:= 'dropdown-item';
          end
        else
          begin
            menuitem.className:= 'nav-item';
            a.className:= 'nav-link';
          end;
      end;
    'menu':
      begin
        a:= TJSHTMLAnchorElement(Document.createElement('a'));
        a['href']:='#';
        a.id:= aId;
        a.innerText:= aName;
        a.onclick:= @onMenuClick;
        menuitem.appendChild(a);
        ul:= TJSHTMLElement(Document.createElement('ul'));
        menuitem.appendChild(ul);
        result:= ul;
        if isSubnode then
          begin
            menuitem.className:='dropdown-submenu';
            a.className:= 'dropdown-item dropdown-toggle';
          end
        else
          begin
            menuitem.className:= 'nav-item dropdown';
            a.className:= 'nav-link dropdown-toggle';
          end;
        ul.className:='dropdown-menu';
      end;
    'input':
      begin
        aData:= TJSObject(JO.Properties['data']);
        menuitem.innerText:= aName;
        menuitem.className:= 'nav-item';
        input:= TJSHTMLInputElement(Document.createElement('input'));
        input.className:= 'form-control';
        input.id:= aId;
        input.value:= string(aData.Properties['value']);
        input._type:= string(aData.Properties['type']);
        menuitem.appendChild(input);
      end;
    'select':
      begin
        menuitem.innerText:= aName;
        menuitem.className:= 'nav-item';
        select:= TJSHTMLSelectElement(Document.createElement('select'));
        select.className:= 'form-select';
        select.id:= aId;
        menuitem.appendChild(select);
        result:= select;
      end;
    'option': //,'option selected':
      begin
        aData:= TJSObject(JO.Properties['data']);
        option:= TJSHTMLOptionElement(Document.createElement('option'));
        option.innerText:= aName;
        option.value:= string(aData.Properties['value']);
        option.selected:= toBoolean(aData.Properties['selected']);//aType = 'option selected';
        aParent.appendChild(option);
        result:= select;
      end;
    'optgroup':
      begin
        optgroup:= TJSHTMLOptGroupElement(Document.createElement('optgroup'));
        optgroup.Attrs['label']:= aName;
        optgroup.id:= aId;
        aParent.appendChild(optgroup);
        result:= optgroup;
      end;

  end;

end;

procedure TMenu2Application.CreateMenu(aParent: TJSHTMLElement;
  aJson: string; isSubnode:boolean=False);
var
  i: integer;
  JA: TJSArray;
  JO: TJSObject;
  menuitem: TJSHTMLElement;
begin
  //writeln('JSON: ', aJson);
  JA:= TJSArray(TJSJSON.parse(aJson));
  for i:=0 to Pred(JA.Length) do
  begin
    JO:= TJSObject(JA[i]);
    writeln('id:', JO.Properties['id'], ' name:', JO.Properties['name'], ' type:', JO.Properties['type']);
    menuitem:= CreateMenuItem(aParent, JO, isSubnode);
    if JO.Properties['type']='menu' then CreateMenu(menuitem, TJSJSON.stringify(JO.Properties['data']), True);
    if JO.Properties['type']='select' then CreateMenu(menuitem, TJSJSON.stringify(JO.Properties['data']), True);
    if JO.Properties['type']='optgroup' then CreateMenu(menuitem, TJSJSON.stringify(JO.Properties['data']), True);
   end;
end;

procedure TMenu2Application.ClearShowAttribute(aParentNode: TJSHTMLElement; aNodeExclude: TJSHTMLElement= nil);
var
  i: integer;
  aNode: TJSHTMLElement;
begin
  for i:=0 to Pred(aParentNode.childElementCount) do
    begin
      aNode:= TJSHTMLElement(aParentNode.children[i]);
      if aNode<>aNodeExclude then
        begin
          aNode.classList.remove('show');
          if aNode.hasChildNodes then ClearShowAttribute(aNode);
        end;
    end;
end;

procedure TMenu2Application.SetShowAttribute(aNode: TJSHTMLElement);
var
  aParentNode: TJSHTMLElement;
  aSiblingNode: TJSHTMLElement;
  i: integer;
  isDropdownMenu: Boolean;
  //nextParentFound: Boolean;
begin
  isDropdownMenu:= False;
  //nextParentFound:= False;
  aParentNode:= TJSHTMLElement(aNode.parentElement);

  for i:=0 to Pred(aParentNode.childElementCount) do
    begin
      aSiblingNode:= TJSHTMLElement(aParentNode.children[i]);
      if aSiblingNode<>null then
        if aSiblingNode.classList.contains('dropdown-menu') then
          begin
            aSiblingNode.classList.toggle('show');
            isDropdownMenu:= True;
            writeln('usuwam klasę show na siblingach elementu ', aParentNode.tagName, ' ', aParentNode.id);
            ClearShowAttribute(TJSHTMLElement(aParentNode.parentElement), aParentNode);
          end;
    end;

  if not isDropdownMenu then
  begin
    writeln('usuwam klasę show (cały nav)');
    ClearShowAttribute(navcontent);
  end;
   //while (not nextParentFound) and (aParentNode<>null) do
  //  begin
  //    if aParentNode.classList.contains('dropdown-menu') then
  //      begin
  //        nextParentFound:= True;
  //        aNode:= aParentNode;
  //      end;
  //    writeln(aParentNode.tagName);
  //    aParentNode:= TJSHTMLElement(aParentNode.parentElement);
  //  end;
  //
  //if nextParentFound then
  //begin
  //  writeln('clearing show on siblings');
  //  for i:=0 to Pred(aParentNode.childElementCount) do
  //    begin
  //      aSiblingNode:= TJSHTMLElement(aParentNode.children[i]);
  //      if (aSiblingNode<>null) and (aSiblingNode<>aNode) then
  //        ClearShowAttribute(aSiblingNode);
  //    end;
  //end;

end;

procedure TMenu2Application.doRun;
begin
  BindElements;
  //DeleteMenu(navcontent);
  //CreateMenu(navcontent, menuData);
  ShowLoginForm('menuLogoff');
  //logincontent.style['display']:='block';
  //pokaz('start2');
  Terminate;
end;

function TMenu2Application.onMenuClick(Event: TJSMouseEvent): boolean;
var
  aSenderId: string;
  i: integer;
  sl: TStringList;
  //formParams: array of String;
  //formParam: string;

  aHTMLFormCollection: TJSHTMLCollection;
  aHTMLNode: TJSHTMLElement;
  aHTMLForm: TJSHTMLFormElement;
  aHTMLInput: TJSHTMLInputElement;
  aDataset: TJSObject;
  aDataTarget: string;
  aDataUrl: string;
  aDataForm: string;
  aDataParams: string;
  aDataDescription: string;
begin
  aSenderId:= event.targetElement.id;
  //event.preventDefault;
  //event.stopPropagation;

  aHTMLNode:= TJSHTMLElement(event.targetElement);
  writeln('menu click -> Tag:' + aHTMLNode.tagName + ' Id:' + aSenderId);
  SetShowAttribute(aHTMLNode);

  aDataset:= aHTMLNode.dataset;
  aDataTarget:= js.toString(aDataset.Properties['target']);
  aDataUrl:= js.toString(aDataset.Properties['url']);
  aDataForm:= js.toString(aDataset.Properties['form']);
  aDataParams:= js.toString(aDataset.Properties['params']);
  aDataDescription:= js.toString(aDataset.Properties['description']);
  writeln('target:', aDataTarget, ' url:', aDataUrl);


  sl:= TStringList.Create;
  sl.Delimiter:='&';
  if aDataForm <> '' then
    if aDataForm.StartsWith('.') then
    begin
      aHTMLFormCollection:= document.getElementsByClassName(RightStr(aDataForm, aDataForm.Length-1));
      for i:= 0 to Pred(aHTMLFormCollection.length) do
        begin
          aHTMLInput:= TJSHTMLInputElement(aHTMLFormCollection.item(I));
          sl.Add(aHTMLInput.name + '=' + aHTMLInput.value);
        end;
    end
    else
    begin
      sl.DelimitedText:= aDataForm;
      //writeln('form:', sl.Text);
      i:= 0;
      while i < sl.Count do
      begin
        writeln(i, '/', Pred(sl.Count), ' param:', sl.Names[i], ' val:', sl.ValueFromIndex[i], ' lookup:', TJSHTMLInputElement(GetHTMLElement(sl.ValueFromIndex[i])).value);
        if TJSHTMLInputElement(GetHTMLElement(sl.ValueFromIndex[i])).value <> '' then
        begin
          sl.ValueFromIndex[i]:= TJSHTMLInputElement(GetHTMLElement(sl.ValueFromIndex[i])).value;
          inc(i);
        end
        else
          sl.Delete(i);
      end
    end;
  if aDataParams <> '' then
    if sl.Count>0 then
      sl.DelimitedText:= sl.DelimitedText + '&' + aDataParams
    else
      sl.DelimitedText:= sl.DelimitedText + aDataParams;
  if aDataDescription <> '' then
    begin
      sl.Add('Opis=' + encodeURIComponent(aDataDescription));
      sl.Add('Description=' + aDataDescription);
    end;

  //writeln(sl.DelimitedText);

  case aDataTarget of
  'xhr':
    begin
      varcontent.innerHTML:= '<hr>Wysłano żądanie: ' + sl.Values['Description'] + '. Proszę czekać ...<hr>';
      //for i:= 0 to Pred(sl.Count) do sl.ValueFromIndex[i]:= encodeURIComponent(sl.ValueFromIndex[i]);
      xhr:=TJSXMLHttpRequest.New;
      xhr.addEventListener('load', @onLoadData);
      xhr.open('Post', aDataUrl, true);
      //xhr.open('Get', './jsondata/menudata.json', true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader('charset', 'UTF-8');
      xhr.send(sl.DelimitedText);
      //xhr.send();

      writeln('wysłano żądanie xhr:', aDataUrl, ' parametry:', sl.DelimitedText );
    end;

  'iframe':
    begin
      varcontent.innerHTML:= '<iframe src="' + aDataUrl + '" ' +
                             'height="' +(window.innerHeight - 65).ToString + '" ' +
                             'width="' + (window.innerWidth - 10).ToString + '" ' +
                             'style="border: 0px none"></iframe>';
    end;
  '_blank','_self':
    begin
      aHTMLForm := TJSHTMLFormElement(Document.createElement('form'));
      for i:= 0 to Pred(sl.Count) do
        begin
          aHTMLInput:= TJSHTMLInputElement(Document.createElement('input'));
          aHTMLInput._type:= 'hidden';
          aHTMLInput.name:= sl.Names[i];
          aHTMLInput.value:= sl.ValueFromIndex[i];
          aHTMLForm.appendChild(aHTMLInput);
        end;
      aHTMLForm.action:= aDataUrl;
      aHTMLForm.target:= aDataTarget;
      aHTMLForm.method:= 'POST';

      varcontent.appendChild(aHTMLForm);
      aHTMLForm.submit;
      writeln('wysłano żądanie ' + aHTMLForm.method + ':', aHTMLForm.action, ' -> ', aHTMLForm.target );
      aHTMLForm.remove;
    end;
  else
    begin
      ShowLoginForm(aSenderId);
    end;
  end;

  sl.Free;
end;

function TMenu2Application.onLoadData(Event: TEventListenerEvent
  ): boolean;
var
  JO: TJSObject;
begin
  case xhr.status of
  200:
    begin
      writeln('dane załadowane');
      varcontent.innerHTML:= xhr.responseText;
      exit;
    end;
  202:
    begin
      JO:= TJSJSON.parseObject(xhr.responseText);
      writeln('menu załadowane - uwierzytelniono');
      DeleteMenu(navcontent);
      CreateMenu(navcontent, TJSJSON.stringify(JO.Properties['menudata']));
      varcontent.innerHTML:= js.toString(JO.Properties['message']);
      if js.toString(JO.Properties['menucommand']) = 'totp' then
      begin
        ShowLoginForm('menuTotp');
      end
      else
        ShowLoginForm('btncancel');
      exit;
    end;
  403:
    begin
      JO:= TJSJSON.parseObject(xhr.responseText);
      writeln('uwierzytelnienie nieudane');
      DeleteMenu(navcontent);
      CreateMenu(navcontent, TJSJSON.stringify(JO.Properties['menudata']));
      varcontent.innerHTML:= js.toString(JO.Properties['message']);
      exit;
    end;
  else
    begin
      writeln('nie można załadować danych błąd ', xhr.Status, ' ', xhr.StatusText);
    end;
  end;
  varcontent.innerHTML:= Format('<hr>Odpowiedź serwera %d %s<hr>', [xhr.Status,xhr.StatusText]);
end;

procedure TMenu2Application.deleteOldCookies;
begin
  asm
    document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
  end;
end;
function TMenu2Application.pokaz(const tekst: string): boolean;
begin
  writeln(tekst);
  result:= True;
end;

var
  Application : TMenu2Application;

begin
  Application:=TMenu2Application.Create(nil);
  Application.Initialize;
  Application.Run;
  Application.Free;
end.
